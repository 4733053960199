/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

https://polaris.corp.amazon.com/getting_started/development/integration/
************************************************************************/
@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;

.custom-home__header {
  background-color: awsui.$color-background-home-header;
}

.custom-home__header-title {
  color: awsui.$color-text-home-header-default;
}

.custom-home__category,
.custom-home__header-sub-title {
  color: awsui.$color-text-home-header-secondary;
}

@media (min-width: 992px) {
  .custom-home__sidebar {
    margin-top: -6rem;
  }
}

.custom-home__header-title > * {
  max-width: 70rem;
}

.custom-home-image__placeholder:before {
  content: "X";
  display: block;
  background-color: awsui.$color-background-layout-main;
  color: awsui.$color-background-container-content;
  text-align: center;
  font-size: 40rem;
  line-height: 40rem;
}

ul.custom-list-separator {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul.custom-list-separator li {
  border-top: 1px solid awsui.$color-border-divider-default;
  padding: 0.8rem 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

ul.custom-list-separator li:first-child {
  padding-top: 0;
  border-top: none;
}

ul.custom-list-separator li:last-child {
  padding-bottom: 0;
}

.input-container {
  display: flex;
  flex-wrap: wrap;
  order: 0;
  flex-grow: 10;
  margin-right: 0;
  margin-bottom: -1rem;

  > * {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}

.input-filter {
  order: 0;
  flex-grow: 6;
  width: auto;
  max-width: 728px;
}

.select-filter {
  max-width: 130px;
  flex-grow: 2;
  width: auto;
}

.filtering-results {
  margin-left: 1rem;
  line-height: 3rem;
  color: awsui.$color-text-form-default;
}

@media (max-width: 1152px) {
  .input-container {
    margin-right: -1rem;
  }

  .select-filter {
    max-width: none;
  }

  .input-filter {
    width: 100%;
    max-width: none;
  }
}