.container {
    display: flex;
    flex-flow: row wrap;
}

.column {
    flex-basis: 19%;
    flex-grow: 1;
    margin: 0.5% !important;
    min-width: 240px;
    padding: 5px;
}

.fadedHeader {
    padding-top: 3px;
}

.red {
    color: red !important;
}

.defaultLink { 
  position:absolute; 
  width:100%;
  height:100%;
  top:0;
  left: 0;

  z-index: 1;

}  

.target {
    margin-top: -3px;
    font-style: italic;
}

ul.no-bullets {
    list-style-type: none; /* Remove bullets */
}

ul.tight {
    margin: 0;
    padding: 0;
    margin-left: 10px;
    padding-right: 10px;
}

.mybox {
    width: 80px;
    text-align: center;
    padding: 10px;
    box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
    box-shadow: var(--shadow-container-0bkp96, 0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15));
    border-top: 1px solid #eaeded;
    border-top: var(--border-container-top-width-fs2udu, 1px) solid var(--color-border-container-top-1itxac, #eaeded);
    border-radius: 0px;
    border-radius: var(--border-container-radius-gcr27z, 0px);
    box-sizing: border-box;
    background-color: #ffffff;
    background-color: var(--color-background-container-content-o88wxm, #ffffff);
}


.clearfix {
    overflow: auto;
    padding-bottom: 20px;
}

td:has(span.pdpHeaderRow) {
    background-color: #fff5ed;
}

td:has(span.pdpHeaderRowLighter) {
    background-color: #fcfbfa;
}

#assessmentDashboard::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: #fff;
    width: 16px;    
}
#assessmentDashboard::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;    
}
#assessmentDashboard::-webkit-scrollbar-button {
    display:none;
}

.pdpRowIcon {
    padding-top: 3px !important;
}

.successVariantGreen {
    color: var(--color-text-status-success-hb5hdl, #1d8102) !important;
}

.successVariantGreenLighter {
    color: var(--color-text-status-success-hb5hdl, #dce6d9) !important;
}

.chrono-card {
    cursor: pointer !important;
}